<template>
  <div id="app">
    <notifications group="alert" position="top right" />
    <router-view />
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    ...mapActions(["setToken", "setRole"]),
  },
  mounted() {
    // check if token is still valid
    if (this.token) {
      const { exp } = jwt_decode(this.token);
      if (exp * 1000 <= new Date()) {
        this.setToken();
        this.setRole();
      }
    }
  },
};
</script>

<style>
.card-img-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.close {
  font-size: 30px !important;
  cursor: pointer;
  position: fixed;
  right: 15px;
  top: 15px;
}
.footer {
  padding: 5px 10px;
  font-size: 0.9rem;
}

.multiselect__tags {
  border: 1px solid #ced4da !important;
}

html {
  scroll-behavior: smooth;
}
</style>
