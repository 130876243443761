import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

function store(key, value) {
  if (value === "null" || value === null || value === undefined) {
    sessionStorage.removeItem(key);
  } else {
    sessionStorage.setItem(key, value);
  }
  
  return sessionStorage.getItem(key)
}

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem("TOKEN"),
    role: sessionStorage.getItem("ROLE"),
  },

  getters: {
    token: (state) => {
      state.token = sessionStorage.getItem("TOKEN");
      return state.token;
    },
    role: (state) => {
      state.role = sessionStorage.getItem("ROLE");
      return state.role;
    }
  },

  mutations: {
    setToken: (state, token) => {
      state.token = store("TOKEN", token);
    },
    setRole: (state, role) => {
      state.role = store("ROLE", role);
    }
  },

  actions: {
    setToken: ({ commit }, token) => {
      commit("setToken", token);
    },
    setRole: ({ commit }, role) => {
      commit("setRole", role);
    }
  }
})
