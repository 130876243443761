import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const adminGuard = (to, from, next) => {
  const role = sessionStorage.getItem("ROLE");
  if (role !== "kliba") {
    next({ name: "Admin Home" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/admin",
    name: "Admin View",
    component: () => import("../views/admin/index.vue"),
    redirect: "/admin/home",
    children: [
      {
        path: "/admin/home",
        name: "Admin Home",
        component: () => import("../views/admin/views/Home.vue"),
      },
      {
        path: "/admin/tabelle/kommune",
        name: "PV-Table Municipality",
        component: () =>
          import("../views/admin/views/KommunaleAnlagentabelle.vue"),
        beforeEnter: adminGuard,
      },
      {
        path: "/admin/tabelle/solarteur",
        name: "Solarteur Table",
        component: () => import("../views/admin/views/Solarteurtabelle.vue"),
        beforeEnter: adminGuard,
      },
      {
        path: "/admin/foerderprogramm",
        name: "Municipality Program",
        component: () => import("../views/admin/views/Foerderprogramm.vue"),
        beforeEnter: adminGuard,
      },
      {
        path: "/admin/tabelle/privat",
        name: "PV-Table Private",
        component: () =>
          import("../views/admin/views/PrivateAnlagentabelle.vue"),
        beforeEnter: adminGuard,
      },
    ],
  },
  {
    path: "/formular/privat/:id?",
    name: "private form",
    component: () => import("../views/formular/Privat.vue"),
    props: true,
  },
  {
    path: "/formular/kommune/:id?",
    name: "municipality form",
    component: () => import("../views/formular/Kommune.vue"),
    props: true,
  },
  {
    path: "/formular/solarteur/:id?",
    name: "solarteur form",
    component: () => import("../views/formular/Solarteur.vue"),
    props: true,
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () => import("../views/Datenschutz.vue"),
  },
  {
    path: "/datenschutz-solarteur",
    name: "Datenschutz Solarteur",
    component: () => import("../views/DatenschutzSolarteur.vue"),
  },
  {
    path: "*",
    component: () => import("../views/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
